<template>
    <div class="mt-12px mt-md-24px mt-lg-36px">
        <v-row align="center" class="row--xs">
            <v-col cols="auto">
                <icon-symbol data-aos="zoom-out" data-aos-delay="200" :white="white" />
            </v-col>
            <v-col cols="auto">
                <!-- <txt data-aos="zoom-out" data-aos-delay="400" v-bind="$attrs" class="font-weight-bold line-height-1" :class="dark ? 'white--text' : 'txt--dark'"><slot /></txt> -->
                <tit data-aos="zoom-out" data-aos-delay="400" v-bind="$attrs" class="line-height-1" :class="dark ? 'white--text' : 'txt--dark'"><slot /></tit>
            </v-col>
            <v-col md="auto">
                <div class="overflow-hidden">
                    <v-divider data-aos="fade-right" class="mw-120px mw-md-180px w-md-180px" :class="dark ? 'white' : 'grey lighten-3'" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Txt from "@/components/publish/styles/typography/txt.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import IconSymbol from "@/components/publish/styles/icons/icon-symbol.vue";

export default {
    props: {
        dark: { type: Boolean, default: false },
        white: { type: Boolean, default: false },
    },
    components: {
        Txt,
        Tit,
        IconSymbol,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>