var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "main-section--business"
  }, [_c('div', {
    staticClass: "main-section--business__inner"
  }, [_c('tit-wrap-primary', {
    staticClass: "main-section--business__tit",
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("B")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("USINESS")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("주요업무")];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "main-section--business__contents"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "w-100 ml-auto mb-30px mb-md-60px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200",
      "src": "/images/main/business-img.jpg",
      "max-width": "1196",
      "aspect-ratio": 1196 / 348
    }
  }), _c('v-row', {
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'border-l border-b' : 'border-b',
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 200 * (index + 1),
        "cols": "12",
        "md": item.large ? 8 : 4
      }
    }, [_c('card-secondary', {
      staticClass: "business-item h-100 v-card--none-active-bg",
      style: _vm.$vuetify.breakpoint.mdAndUp ? 'border-left: 0; border-bottom: 0;' : 'border-bottom: 0;',
      attrs: {
        "to": item.link
      }
    }, [_c('div', {
      staticClass: "pa-16px pa-md-24px"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": "",
        "align": "end"
      }
    }, [_c('v-col', [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-semibold line-height-1 primary--text"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('div', {
      staticClass: "mt-20px mt-md-24px"
    }, [_c('u-tit-default', {
      staticClass: "tit--sm"
    }, [_vm._v(_vm._s(item.title))]), _c('u-txt-default', {
      staticClass: "txt--sm txt--light line-height-15 mt-4px mt-md-8px"
    }, [_vm._v(_vm._s(item.txt))])], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('icon-arrow-primary', {
      staticClass: "grey--text text--lighten-3"
    })], 1)], 1)], 1)])], 1);
  }), 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }