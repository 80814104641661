<template>
    <section class="main-section--check grey lighten-5">
        <div class="main-section--check__left">
            <v-container class="container--lg">
                <v-row>
                    <v-col xl="6">
                        <tit-wrap-primary>
                            <template #titHead>C</template>
                            <template #tit>HECK LIST</template>
                            <template #titAdd>성범죄 로펌 선택 전 <span class="font-primary">11</span>가지 체크리스트</template>
                        </tit-wrap-primary>
                        <!-- <v-img src="/images/main/check-bg-mo.svg" :aspect-ratio="880 / 552" contain class="w-60 d-xl-none ml-auto" /> -->
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="main-section--check__right">
            <v-row no-gutters>
                <v-col xl="6" offset-xl="6">
                    <div class="main-section--check__right__inner">
                        <card-primary color="white">
                            <div class="pa-20px pa-md-30px pa-lg-60px">
                                <div class="pb-30px pb-md-48px">
                                    <u-tit-default data-aos="fade-up" class="tit--sm font-weight-semibold line-height-1 primary--text mb-12px mb-md-24px">
                                        Question
                                    </u-tit-default>
                                    <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm">
                                        성범죄 특화 전문 로펌인가?
                                    </u-tit-default>
                                </div>
                                <v-row class="row--xs">
                                    <v-col v-for="(detail, index) in info" :key="index" cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="auto">
                                                <u-tit-default data-aos="fade-right" class="tit--sm font-weight-semibold line-height-1 w-40px">
                                                    <span v-if="index<9">0</span>{{index+1}}
                                                </u-tit-default>
                                            </v-col>
                                            <v-col>
                                                <u-txt-default data-aos="fade-left" class="line-height-15">
                                                    <p v-html="detail"></p>
                                                </u-txt-default>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </card-primary>
                    </div>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

export default {
    components: {
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardPrimary,
    },
    data() {
        return {
            info: [
                "성범죄 전담 <span class='font-weight-semibold' />재판부 판사출신 변호사</span>가 있는가?",
                "<span class='font-weight-semibold' />판사출신</span>의 전문가가 <span class='font-weight-semibold' />상주</span>하는가?",
                "판사출신 대표변호사가 <span class='font-weight-semibold' />직접 상담</span>하는가?",
                "경찰·검찰 조사 및 재판 전에 의뢰인이 <span class='font-weight-semibold' />철저한 준비를 할 수 있도록 조력</span>하는가?",
                "사무장이 상주하지 않으며 <span class='font-weight-semibold' />담당 변호사가 의뢰인과 직접 소통</span>하는가?",
                "<span class='font-weight-semibold' />대표변호사와의 소통이 자주</span> 이루어지는가?",
                "성범죄 사건 해결에 <span class='font-weight-semibold' />특화된 시스템</span>을 운영하는가?",
                "내가 <span class='font-weight-semibold' />원하는 방향을 충족</span>시켜주는 조력자인가?",
                "<span class='font-weight-semibold' />전문성</span>과 <span class='font-weight-semibold' />노하우</span> 둘 다 갖춘 성범죄 전문 로펌인가?",
                "대표변호사의 <span class='font-weight-semibold' />변호 철학이 확실</span>한가?",
                "마케팅에 과도한 비용을 투자하며 규모와 사건 해결 건수만을 강조하는 곳인가?",
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
.main-section--check{
    position: relative;
    &__left{
        padding-top: var(--page-section-padding-y);
    }
    &__right{
        position: relative;
        z-index: 1;
        &__inner{
            width: 100%;
            padding: var(--page-section-padding-y) var(--container-gutter);
            background-image: url(/images/main/check-bg.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-section--check{
        position: relative;
        &__left{
            padding: var(--page-section-padding-y) 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url(/images/main/check-bg2.svg);
            background-repeat: no-repeat;
            background-size: calc(50% - 80px) auto;
            background-position: left 40px bottom;
        }
        &__right{
            &__inner{
                padding: var(--page-section-padding-y);
            }
        }
    }
}

</style>