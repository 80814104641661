var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("I")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("NSIGHTS")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("언론기사")];
      },
      proxy: true
    }, {
      key: "titRight",
      fn: function () {
        return [_c('btn-secondary', {
          staticClass: "w-100 v-btn--width-fixed",
          attrs: {
            "to": "/insight_media/insight",
            "large": ""
          }
        }, [_vm._v("More View")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "insight-slide",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('swiper', {
    ref: "swiperInsights",
    attrs: {
      "options": _vm.swiperOptionInsights
    }
  }, _vm._l(_vm.insights, function (insight, index) {
    return _c('swiper-slide', {
      key: index,
      staticClass: "swiper swiper--insights"
    }, [_c('card-primary', {
      staticClass: "insight-item plus-btn-card v-card--none-active-bg",
      attrs: {
        "to": `/insight_media/insight/${insight._id}`
      }
    }, [_c('div', {
      staticClass: "insight-item__inner"
    }, [_c('v-responsive', {
      staticClass: "insight-item__thumb-wrap",
      attrs: {
        "aspect-ratio": 1 / 1,
        "content-class": "d-flex align-end"
      }
    }, [_c('div', {
      staticClass: "insight-item__thumb"
    }, [_c('v-responsive', {
      staticClass: "image-card insight-item__thumb-inner",
      style: `background-image: url('${_vm.PANSIM}${insight.thumb}')`,
      attrs: {
        "aspect-ratio": 1 / 1
      }
    })], 1)]), _c('div', {
      staticClass: "insight-item__txt mt-20px mt-md-24px"
    }, [_c('u-txt-default', {
      staticClass: "mt-8px mb-md-16px"
    }, [_vm._v(_vm._s(insight.label))]), _c('u-tit-default', {
      staticClass: "tit--sm text-truncate-2 font-weight-medium line-height-15"
    }, [_vm._v(" " + _vm._s(insight.subject) + " ")]), _c('icon-plus', {
      staticClass: "mt-24px mt-md-48px grey--text"
    })], 1)], 1)])], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }