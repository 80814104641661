<template>
    <section class="main-section--professional">
        <v-container class="container--lg">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="main-section--professional__contents main-section--professional__contents--left">
                        <tit-wrap-primary dark>
                            <template #titHead>P</template>
                            <template #tit>ROFESSIONAL</template>
                            <template #titAdd>변호사 소개</template>
                        </tit-wrap-primary>
                        <card-secondary data-aos="zoom-out" dark color="white" class="mb-16px mb-md-32px" style="width: fit-content">
                            <div class="px-12px py-4px">
                                <u-tit-default class="tit--lg font-weight-regular line-height-12"> 판사 출신 대표 변호사 </u-tit-default>
                            </div>
                        </card-secondary>
                        <card-primary :to="`/member/${lawyer._id}`" color="transparent" class="plus-btn-card v-card--none-active-bg">
                            <div class="mb-12px mb-md-24px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <u-tit-default data-aos="fade-up" class="white--text line-height-1">{{ lawyer.name }}</u-tit-default>
                                    </v-col>
                                    <!-- Mobile + icon -->
                                    <v-col cols="auto" class="d-md-none">
                                        <icon-plus size="small" class="white--text" />
                                    </v-col>
                                    <!-- // Mobile + icon -->
                                </v-row>
                            </div>
                            <v-row data-aos="fade-up" class="lawyer-info ma-n4px mx-md-n20px">
                                <v-col v-for="(detail, index) in lawyer.career" :key="index" cols="12" md="auto" class="pa-4px px-md-20px">
                                    <txt-dot class="grey--text text--lighten-3"><span v-html="detail.txt"></span></txt-dot>
                                </v-col>
                            </v-row>
                            <!-- PC + icon -->
                            <div data-aos="fade-up" data-aos-delay="200" class="mt-48px white--text d-none d-md-block">
                                <icon-plus class="white--text" />
                            </div>
                            <!-- // PC + icon -->
                        </card-primary>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="main-section--professional__contents main-section--professional__contents--right overflow-hidden">
                        <v-img data-aos="fade-up" data-aos-delay="400" :src="`${PANSIM}${lawyer.mainImage}`" max-width="708" :aspect-ratio="708 / 708" class="w-100 mx-auto" />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import api from "@/api";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import TxtDot from "@/components/publish/styles/typography/txt-dot.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    components: {
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        TxtDot,
        CardPrimary,
        CardSecondary,
        IconPlus,
    },
    data() {
        return {
            PANSIM: process.env.VUE_APP_PASIM_URL,
            lawyer: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query),
            });
            try {
                let { summary, lawyers } = await api.v1.lawyers.gets({
                    params: {
                        isRepresentative: true,
                    },
                });

                let { career, ...lawyer } = lawyers[0];
                career = lawyers[0].career.filter((career) => career.isMain === true);
                this.lawyer = { career, ...lawyer };
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section--professional {
    padding-top: var(--page-section-padding-y);
    max-width: 1920px;
    margin: 0 auto;
    background-color: var(--v-grey-darken4);
    &__contents {
        &--right {
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                background-image: url(/images/main/professional-bg-mo.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section--professional {
        padding-top: 0;
        position: relative;
        background-color: transparent;
        &__contents {
            height: 100%;
            padding-top: var(--page-section-padding-y);
            position: relative;
            &--left {
                padding-bottom: 120px;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100vw;
                    height: 100%;
                    background-color: var(--v-grey-darken4);
                    top: 0;
                    right: calc(var(--grid-gutter) * -2);
                    z-index: -1;
                }
            }
            &--right {
                display: flex;
                align-items: flex-end;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: calc(50% + (var(--grid-gutter) * 1));
                    height: 100%;
                    background-color: var(--v-grey-darken4);
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
                &::before {
                    width: calc(50% - (var(--grid-gutter) * 1));
                    height: 100%;
                    left: unset;
                    right: 0;
                    z-index: -1;
                    background-image: url(/images/main/professional-bg.svg);
                    background-position: right center;
                }
            }
        }
    }
    .lawyer-info {
        overflow: hidden;
        max-height: 160px;
        flex-direction: column;
        > [class*="col"] {
            width: calc(100% / 3);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1640px) {
    .main-section--professional {
        &__contents {
            &--right {
                &::before {
                    display: none;
                }
            }
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            background-image: url(/images/main/professional-bg.svg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: auto 100%;
        }
    }
}
</style>