<template>
    <section class="image-card" style="background-image: url(/images/main/gate-bg.jpg);">
        <v-row no-gutters>
            <v-col v-for="(item, index) in gate" :key="index" cols="12" md="4" lg="2">
                <v-card :href="item.href" target="_blank" color="transparent" flat rounded="0" dark class="gate-item h-100 plus-btn-card v-card--none-active-bg" @click="clickAlert(item)">
                    <div class="gate-item__inner pa-20px pa-md-24px py-lg-160px">
                        <v-row align="center" class="row--xs flex-lg-column h-100">
                            <v-col class="text-lg-center">
                                <u-tit-default data-aos="fade-up"><span v-html="item.title"></span></u-tit-default>
                                <u-txt-default data-aos="fade-up" data-aos-delay="200" class="white--text line-height-15 mt-8px mt-md-16px">{{item.txt}}</u-txt-default>
                            </v-col>
                            <v-col cols="auto">
                                <div data-aos="fade-up" data-aos-delay="400">
                                    <icon-plus />
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    components: {
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        IconPlus,
    },
    data() {
        return {
            gate: [
                {
                    title: "판심 법무법인 <br class='d-none d-xl-block' />메인",
                    txt: "Law Firm Judgemind",
                    href: "https://www.judgemind.co.kr/",
                },
                {
                    title: "교통",
                    txt: "Traffic",
                    href: "https://speed.judgemind.co.kr/",
                },
                {
                    title: "이혼",
                    txt: "Divorce",
                    href: "https://judgemind.kr/",
                },
                {
                    title: "스토킹",
                    txt: "Stalking",
                    href: "https://www.judgemind.com/",
                },
                {
                    title: "교원/학교",
                    txt: "Teacher/School",
                },
                {
                    title: "기업법무 <br class='d-none d-xl-block' />(지식재산권)",
                    txt: "IPRs",
                },
            ],
        };
    },
    methods: {
        clickAlert(item) {
            if (!item.href) {
                alert("준비중입니다.");
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.gate-item{
    width: 100%;
    position: relative;
    transition: .2s ease-out;
    display: flex;
    align-items: center;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .3);
        opacity: 1;
        visibility: visible;
        transition: .2s ease-out;
        z-index: 0;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 48px);
        height: calc(100% - 48px);
        border: 1px solid #fff;
        top: 24px;
        left: 24px;
        transform: scaleY(0);
        opacity: 0;
        visibility: hidden;
        transition: .5s ease-out;
        z-index: 1;
    }
    &__inner{
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
.row >[class*="col"]:not(:first-child){
    .gate-item{
        &__inner{
            border-top: 1px solid rgba(255, 255, 255, .15);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row >[class*="col"]:not(:first-child){
        .gate-item{
            &__inner{
                border-top: 0;
                border-left: 1px solid rgba(255, 255, 255, .15);
            }
        }
    }
    .row >[class*="col"]:nth-child(1),
    .row >[class*="col"]:nth-child(2),
    .row >[class*="col"]:nth-child(3){
        .gate-item{
            &__inner{
                border-bottom: 1px solid rgba(255, 255, 255, .15);
            }
        }
    }
}
@media (min-width:1024px){
    .gate-item{
        min-height: 100vh;
        &::before{
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
        }
    }
    .gate-item:hover{
        background-color: rgba(0, 0, 0, .5) !important;
        &::before{
            opacity: 0;
            visibility: hidden;
        }
        &::after{
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
            transition: transform .5s .1s ease-out, opacity 1s, visibility 1s;
        }
    }
    .row >[class*="col"]:nth-child(1),
    .row >[class*="col"]:nth-child(2),
    .row >[class*="col"]:nth-child(3){
        .gate-item{
            &__inner{
                border-bottom: 0;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>
