var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-header-fixed', {
    staticClass: "header--lnb-all"
  }, [_c('u-header-body-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', [_vm._v("판심 법무법인 - 변변변성범죄센터")])];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('u-gnb-filled', {
          staticClass: "text-center",
          attrs: {
            "gnbName": "gnb",
            "lnbTag": "u-lnb-all-opacity-black"
          }
        })];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }