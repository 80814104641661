var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-footer-basic', [_c('u-footer-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-secondary', [_vm._v("판심 법무법인 - 변변변성범죄센터")])];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('footer-info')], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--light line-height-17"
        }, [_vm._v("Copyright 2023. 판심 법무법인 All Rights Reserved.")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }