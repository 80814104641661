<template>
    <tit v-bind="$attrs">
        <span data-aos="zoom-in" data-aos-delay="1000" class="tit__dot"></span>
        <slot />
    </tit>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        Tit,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit{
    width: fit-content;
    position: relative;
    &__dot{
        position: absolute;
        display: block;
        width: 38px;
        height: 38px;
        top: 0;
        right: -26px;
        border-radius: 50%;
        background-color: rgba(209, 41, 71, .4);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit{
        &__dot{
            width: 56px;
            height: 56px;
            right: -32px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
