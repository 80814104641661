<template>
    <main-section-primary>
        <template #titHead>I</template>
        <template #tit>NSIGHTS</template>
        <template #titAdd>언론기사</template>
        <template #titRight>
            <btn-secondary to="/insight_media/insight" large class="w-100 v-btn--width-fixed">More View</btn-secondary>
        </template>
        <div data-aos="fade-up" data-aos-delay="200" class="insight-slide">
            <swiper ref="swiperInsights" :options="swiperOptionInsights">

                <!-- S: insight -->
                <swiper-slide  v-for="(insight, index) in insights" :key="index" class="swiper swiper--insights">
                    <card-primary :to="`/insight_media/insight/${insight._id}`" class="insight-item plus-btn-card v-card--none-active-bg">
                        <div class="insight-item__inner">
                            <v-responsive :aspect-ratio="1 / 1" class="insight-item__thumb-wrap" content-class="d-flex align-end">
                                <div class="insight-item__thumb">
                                    <v-responsive :aspect-ratio="1 / 1" class="image-card insight-item__thumb-inner" :style="`background-image: url('${PANSIM}${insight.thumb}')`" />
                                </div>
                            </v-responsive>
                            <div class="insight-item__txt mt-20px mt-md-24px">
                                <u-txt-default class="mt-8px mb-md-16px">{{ insight.label }}</u-txt-default>
                                <u-tit-default class="tit--sm text-truncate-2 font-weight-medium line-height-15">
                                    {{ insight.subject }}
                                </u-tit-default>
                                <icon-plus class="mt-24px mt-md-48px grey--text" />
                            </div>
                        </div>
                    </card-primary>                    
                </swiper-slide>
                <!-- E: insight -->

            </swiper>
        </div>
    </main-section-primary>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from "@/assets/variables"
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        BtnSecondary,
        CardPrimary,
        IconPlus,
    },
    data() {
        return {
            PANSIM: process.env.VUE_APP_PASIM_URL,
            insights: [],
            filter: { 
                code: "article",                            // 영상자료
                site: PANSIM_TYPES.SEX_OFFENCE.value,       // 이혼 사이트
                // isSort: true                             // 우선 순위 적용
                isSexMain: true 
            },
            swiperOptionInsights: {
                loop: false,
                slidesPerView: 2,
                spaceBetween: -60,
                speed: 500,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    768:{
                        slidesPerView: 3,
                        spaceBetween: -100,
                    },
                    1024:{
                        slidesPerView: 4,
                        spaceBetween: -140,
                        loopedSlides: 5,
                    },
                },
            },
        };
    },
    mounted (){
        this.init();
    },
    methods: {
        async init(){
            this.search();
        },
        async search(){
            try{
                let { summary, insights } = await api.v1.insights.gets({
                    headers: { limit: 9 },
                    params: this.filter
                })

                // insights = insights.filter((insight)=> insight.isSexMain === true)

                this.insights = insights;

            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.insight-slide{
    --slide-margin: 20px;
    --slide-image-gap: 60px; // active, default 이미지 width 차이
    margin-right: calc(var(--slide-margin) * -1);
    transition: .3s ease-out;
    .swiper-slide-next{
        margin-left: var(--slide-image-gap) !important;
    }
    .swiper-slide-active{
        .insight-item__thumb-inner{
            box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity));
        }
    }
    ::v-deep{
        .swiper-container{
            overflow: unset;
        }
    }
    .insight-item{
        overflow: unset;
        &__inner{
            padding-right: var(--slide-margin);
        }
        &__thumb{
            width: 100%;
            transition: .3s ease-out;
            &-inner{
                transition: .2s ease-out;
            }
            &-wrap{
                overflow: unset;
            }
            &:not(.swiper-slide-active .insight-item__thumb){
                padding-right: var(--slide-image-gap);
            }
        }
        &__txt:not(.swiper-slide-active .insight-item__txt){
            max-width: calc(100% - var(--slide-image-gap));
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .insight-slide{
        --slide-margin: 40px;
        --slide-image-gap: 100px;
    }
}
@media (min-width:1024px){
    .insight-slide{
        --slide-margin: 60px;
        --slide-image-gap: 140px;
        .swiper-slide-active{
            .insight-item__thumb-inner{
                box-shadow: var(--shadow-elevation-16) rgba(var(--shadow-color), var(--shadow-opacity));
            }
        }
    }
}
@media (min-width:1200px){
}

</style>