<template>
    <main-section-primary class="main-section--business">
        <div class="main-section--business__inner">
            <tit-wrap-primary class="main-section--business__tit">
                <template #titHead>B</template>
                <template #tit>USINESS</template>
                <template #titAdd>주요업무</template>
            </tit-wrap-primary>
            <div class="main-section--business__contents">
                <v-row>
                    <v-col cols="12" md="10" offset-md="2">
                        <v-img data-aos="fade-up" data-aos-delay="200" src="/images/main/business-img.jpg" max-width="1196" :aspect-ratio="1196 / 348" class="w-100 ml-auto mb-30px mb-md-60px" />
                        <v-row no-gutters :class="$vuetify.breakpoint.mdAndUp ? 'border-l border-b' : 'border-b'">
                            <v-col v-for="(item, index) in info" :key="index" data-aos="fade-up" :data-aos-delay="200*(index+1)" cols="12" :md="item.large ? 8 : 4">
                                <card-secondary :to="item.link" class="business-item h-100 v-card--none-active-bg" :style="$vuetify.breakpoint.mdAndUp ? 'border-left: 0; border-bottom: 0;' : 'border-bottom: 0;'">
                                    <div class="pa-16px pa-md-24px">
                                        <v-row no-gutters align="end">
                                            <v-col>
                                                <u-tit-default class="tit--sm font-weight-semibold line-height-1 primary--text">0{{index+1}}</u-tit-default>
                                                <div class="mt-20px mt-md-24px">
                                                    <u-tit-default class="tit--sm">{{item.title}}</u-tit-default>
                                                    <u-txt-default class="txt--sm txt--light line-height-15 mt-4px mt-md-8px">{{item.txt}}</u-txt-default>
                                                </div>
                                            </v-col>
                                            <v-col cols="auto">
                                                <icon-arrow-primary class="grey--text text--lighten-3" />
                                            </v-col>
                                        </v-row>
                                    </div>
                                </card-secondary>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    components: {
        MainSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
        IconArrowPrimary,
    },
    data() {
        return {
            info: [
                {
                    title: "성폭행",
                    txt: "Sexual assault",
                    link: "/business/sexual-assault",
                },
                {
                    title: "성추행",
                    txt: "Sexual harassment",
                    link: "/business/sexual-harassment",
                },
                {
                    title: "성매매",
                    txt: "Prostitution",
                    link: "/business/prostitution",
                },
                {
                    title: "보안처분",
                    txt: "Security disposition",
                    link: "/disposition",
                },
                {
                    title: "변변변성범죄센터만의 사건별 맞춤서비스",
                    txt: "Customized service",
                    link: "/service",
                    large: true,
                },
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
    .main-section--business{
        &__inner{
            position: relative;
        }
        &__tit{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        &__contents{
            padding-top: calc(var(--tit-font-size-xl) / 2);
        }
    }
}
@media (min-width:1024px){
    .business-item{
        transition: .2s ease-out;
    }
    .v-application{
        .business-item:hover{
            background-color: var(--v-primary-base) !important;
            *{
                color: #fff !important;
            }
        }
    }
}
@media (min-width:1200px){
}
</style>