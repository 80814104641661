<template>
    <main-section-primary class="pt-0 overflow-hidden">
        <template #mainSectionHead>
            <v-img data-aos="fade-up" src="/images/main/story-img.jpg" max-width="1920" :aspect-ratio="1920 / 500" class="story-img w-100 mx-auto" />
        </template>
        <v-container class="container--lg">
            <tit-wrap-primary>
                <template #titHead>S</template>
                <template #tit>UCCESS STORY</template>
                <template #titAdd>성범죄센터 성공사례</template>
                <template #titRight>
                    <btn-secondary to="/story/firm" large class="w-100 v-btn--width-fixed">More View</btn-secondary>
                </template>
            </tit-wrap-primary>
            <v-row data-aos="fade-up" data-aos-delay="200" class="row--lg">

                <!-- 게시글 6개 노출 -->
                <!-- S: Story -->
                <v-col v-for="(success, index) in successes" :key="index" cols="6" md="4">                    
                    <story-item :to="`/story/${success._id}`" :thumb="success.thumb" large>
                        {{success.subject}}
                        <template #date>{{ $dayjs(success.createdAt).format("YYYY-MM-DD") }}</template>
                    </story-item>
                </v-col>
                <!-- E: Story -->

            </v-row>
        </v-container>
    </main-section-primary>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from "@/assets/variables"
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";   
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";

import StoryItem from "@/components/client/board/skin/story/item.vue";

export default {
    components: {
        MainSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        BtnSecondary,
        StoryItem,
    },
    data() {
        return {
            successes: [],
            filter: { 
                code: "firm",                           // 성공사례
                site: PANSIM_TYPES.SEX_OFFENCE.value,   // 이혼 사이트
                // isSort: true,                        // 우선 순위 적용
                isSexMain: true
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init  (){
            await this.search();
        },
        async search(routable = false){
            if (routable) {
                this.$router.push({
                    query: Object.assign({}, {...this.filter} ),
                });
            }

            try{
                let { summary, successes } = await api.v1.success.gets({
                    headers: { limit: 3 },
                    params: this.filter
                })

                // successes = successes.filter((success)=> success.isSexMain === true);
                
                this.successes = successes;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.story-img{
    margin-bottom: calc(var(--tit-font-size-xl) / -2 );
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-img{
        margin-bottom: -40px;
    }
}
@media (min-width:1200px){
}

</style>