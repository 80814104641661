var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section--professional"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "main-section--professional__contents main-section--professional__contents--left"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("P")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("ROFESSIONAL")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("변호사 소개")];
      },
      proxy: true
    }])
  }), _c('card-secondary', {
    staticClass: "mb-16px mb-md-32px",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "data-aos": "zoom-out",
      "dark": "",
      "color": "white"
    }
  }, [_c('div', {
    staticClass: "px-12px py-4px"
  }, [_c('u-tit-default', {
    staticClass: "tit--lg font-weight-regular line-height-12"
  }, [_vm._v(" 판사 출신 대표 변호사 ")])], 1)]), _c('card-primary', {
    staticClass: "plus-btn-card v-card--none-active-bg",
    attrs: {
      "to": `/member/${_vm.lawyer._id}`,
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "mb-12px mb-md-24px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "white--text line-height-1",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(_vm._s(_vm.lawyer.name))])], 1), _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-plus', {
    staticClass: "white--text",
    attrs: {
      "size": "small"
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "lawyer-info ma-n4px mx-md-n20px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, _vm._l(_vm.lawyer.career, function (detail, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-4px px-md-20px",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('txt-dot', {
      staticClass: "grey--text text--lighten-3"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(detail.txt)
      }
    })])], 1);
  }), 1), _c('div', {
    staticClass: "mt-48px white--text d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('icon-plus', {
    staticClass: "white--text"
  })], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "main-section--professional__contents main-section--professional__contents--right overflow-hidden"
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400",
      "src": `${_vm.PANSIM}${_vm.lawyer.mainImage}`,
      "max-width": "708",
      "aspect-ratio": 708 / 708
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }